import "../scss/main.scss";
import "slick-carousel";

$(function () {
  var adminBar;
  if ($("#wpadminbar")) {
    adminBar = $("#wpadminbar").innerHeight();
  } else {
    adminBar = 0;
  }

  $("main").css("padding-top", $("#masthead").innerHeight());

  if ($("#wpadminbar").length != 0) {
    $("#breadcrumb").css(
      "top",
      $("#masthead").innerHeight() + $("#wpadminbar")
    );
  } else {
    $("#breadcrumb").css("top", $("#masthead").innerHeight());
  }

  $("#up").on("click", function () {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    return false;
  });

  $("#down").on("click", function () {
    $("html, body").animate(
      { scrollTop: $("h1").offset().top - $("#masthead").innerHeight() * 2 },
      "slow"
    );
    return false;
  });

  $("#btNav").on("click", function () {
    $(this).toggleClass("open");
    $(".col--navigation-mobile").toggleClass("open");
  });

  $("#btNavClose").on("click", function () {
    $("#btNav").toggleClass("open");
    $(".col--navigation-mobile").removeClass("open");
  });

  $(".bt-search").on("click", function () {
    $("#search").toggleClass("opensearch");
  });

  $(".search--close").on("click", function () {
    $("#search").removeClass("opensearch");
  });

  $(".years a").on("click", function (event) {
    event.preventDefault();
    var year = $(this).data("year");
    filterEventsByYear(year);
  });

  actionsScroll();
  $(window).on("scroll", function () {
    actionsScroll();
  });

  // INIT ALL FUNCTIONS
  resize();
  if ($(".backImg").length != 0) {
    imgBack();
  }
  if (
    $(".bloc--carrousel").length != 0 ||
    $(".bloc--carrousel-autoplay").length != 0 ||
    $(".bloc--slider").length != 0 ||
    $(".slider--intro").length != 0
  ) {
    sliders();
  }
  if ($(".accordeon").length != 0) {
    accordeon();
  }
  if ($(".tabs").length != 0) {
    onglets();
  }
  animationScroll();

  animation();
  scrollInsideChefsArray();

  // ACTIONS WINDOW
  actionsScroll();
  $(window).on("scroll", function () {
    actionsScroll();
  });

  $(window).on("resize", function () {
    resize();
  });

  customMenu();

  // CHANGE LE TEXTE DES CHAMPS REQUIS DANS GRAVITY FORMS
  $(".gfield_required.gfield_required_text").text("requis");
});

function resize() {
  $("main").css("padding-top", $("#masthead").innerHeight());

  if ($("body.admin-bar").length != 0) {
    $("#masthead").css("top", $("#wpadminbar").innerHeight());
    if ($(window).width() < 587 && $(window).scrollTop() != 0) {
      if ($("body.scrolling")) {
        $("#masthead").css("top", 0);
      }

      $("body.admin-bar #btNav").css(
        "top",
        $("#masthead").innerHeight() * 0.5 - $("#btNav").innerHeight() * 0.5
      );
      $("body.admin-bar #btSearchAndCart").css(
        "top",
        $("#masthead").innerHeight() * 0.5 -
          $("#btSearchAndCart").innerHeight() * 0.5
      );
    } else {
      $("body.admin-bar #btNav").css(
        "top",
        $("#wpadminbar").innerHeight() +
          $("#masthead").innerHeight() * 0.5 -
          $("#btNav").innerHeight() * 0.5
      );
      $("body.admin-bar #btSearchAndCart").css(
        "top",
        $("#wpadminbar").innerHeight() +
          $("#masthead").innerHeight() * 0.5 -
          $("#btSearchAndCart").innerHeight() * 0.5
      );
    }
  }
}

function actionsScroll() {
  $(".site-search").removeClass("active");

  var scrolled = $(window).scrollTop();

  if (scrolled > 0) {
    $("body").addClass("scrolling");
  } else {
    $("body").removeClass("scrolling");
  }

  $(".col--navigation-mobile").removeClass("open");
  $("#btNav").removeClass("open");
}

function imgBack() {
  $(".backImg").each(function () {
    var urlImgCurrent = $(this).children("img").attr("src");
    if (
      urlImgCurrent &&
      urlImgCurrent != undefined &&
      $(this).children("img").length
    ) {
      $(this).attr(
        "style",
        "background-image:url(" +
          urlImgCurrent +
          "); background-position: 50% 50%; background-size: cover; background-repeat: no-repeat;"
      );
      $(this).find("img").remove();
    }
  });
}

function sliders() {
  $(".bloc--carrousel-autoplay")
    .not(".slick-initialized")
    .slick({
      autoplay: true,
      autoplaySpeed: 1,
      speed: 10000,
      respondTo: "slider",
      responsive: true,
      pauseOnFocus: false,
      pauseOnHover: false,
      arrows: false,
      dots: false,
      infinite: true,
      cssEase: "linear",
      centerMode: true,
      centerPadding: "18px",
      prevArrow:
        '<span class="slide-prev"><i class="fa-solid fa-chevron-left"></i></span>',
      nextArrow:
        '<span class="slide-next"><i class="fa-solid fa-angle-right"></i></span>',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

  $(".bloc--carrousel").not(".slick-initialized").slick({
    autoplay: true,
    autoplaySpeed: 9000,
    speed: 1000,
    respondTo: "slider",
    responsive: true,
    arrows: false,
    dots: true,
    infinite: true,
    pauseOnHover: false,
    pauseOnFocus: false,
  });

  $(".intro--slider-wrapper").not(".slick-initialized").slick({
    autoplay: true,
    autoplaySpeed: 9000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: "ease-in-out",
    respondTo: "slider",
    responsive: true,
    arrows: false,
    dots: true,
    infinite: true,
    pauseOnHover: false,
    pauseOnFocus: false,
  });
}

function onglets() {
  var hauteurMax = 0;
  $(".tab-content").each(function () {
    if ($(this).innerHeight() >= hauteurMax) {
      hauteurMax = $(this).innerHeight();
      $(".tab-content").css("min-height", hauteurMax);
    }
  });

  $(".tabs .tab").on("click", function (e) {
    e.preventDefault();

    if (!$(this).parent("li").hasClass("active")) {
      var tab = $(this).attr("data");

      /* On suprime la class active de tous les contenus */
      $(".tab-content").removeClass("active");
      $(".tab-content").hide().delay(200);

      /* On supprime la classe pour tous les liens */
      $(".tabs li").removeClass("active");

      /* On ajoute la classe active à l'onglet qui doit être visible */
      $(tab).addClass("active");
      $(tab).show();

      /* On ajoute la classe pour le lien sur lequel l'internaute vient de cliquer */
      $(this).parent("li").addClass("active");

      return false;
    }
  });
}

function accordeon() {
  $(".bloc--accordeon .accordeon--content").hide();

  // On sélectionne tous les items de liste portant la classe "toggleSubMenu"
  // et on remplace l'élément span qu'ils contiennent par un lien :
  $(".accordeon .accordeon--title").on("click", function () {
    // Si le sous-menu était déjà ouvert, on le referme :
    if ($(this).next(".accordeon--content:visible").length != 0) {
      $(this).next(".accordeon--content").slideUp("normal");
      $(this).removeClass("open");
    }
    // Si le sous-menu est caché, on ferme les autres et on l'affiche :
    else {
      $(".accordeon .accordeon--content").slideUp("normal");
      $(".accordeon .accordeon--title").removeClass("open");
      $(this).next(".accordeon--content").slideDown("normal");
      $(this).addClass("open");
    }
    // On empêche le navigateur de suivre le lien :
    return false;
  });
}

function animationScroll() {
  gsap.registerPlugin(ScrollTrigger);

  ScrollTrigger.defaults({
    toggleActions: "restart pause resume pause",
  });

  let scrollAnimFade = gsap.utils.toArray(".elem--anim-opacity");
  scrollAnimFade.forEach((item) => {
    gsap.from(item, {
      scrollTrigger: {
        trigger: item,
        start: "top 90%",
        end: "top 75%",
        markers: false,
        scrub: true,
      },
      opacity: 0,
      duration: 0.5,
    });
  });

  if ($(".title--anim").length != 0) {
    let scrollAnimTitle = gsap.utils.toArray(".title--anim");
    scrollAnimTitle.forEach((item) => {
      gsap.from(item, {
        scrollTrigger: {
          trigger: item,
          start: "top 90%",
          end: "top 75%",
          markers: false,
          scrub: true,
        },
        opacity: 0,
        duration: 0.5,
      });
    });
  }

  if ($(".elem--anim-top").length != 0) {
    let scrollAnimtop = gsap.utils.toArray(".elem--anim-top");
    scrollAnimtop.forEach((item) => {
      gsap.from(item, {
        scrollTrigger: {
          trigger: item,
          start: "top 90%",
          end: "top 75%",
          markers: false,
          scrub: true,
        },
        opacity: 0,
        y: 90,
        duration: 0.5,
      });
    });
  }

  if ($(".elem--anim-enter").length != 0) {
    gsap.to(".elem--anim-enter", 0.5, { autoAlpha: 1, y: 0 });
  }
}

function animation() {
  $(".slide--cnt").addClass("fadeInUp");

  $(".intro--slider-wrapper").on(
    "afterChange",
    function (event, slick, currentSlide) {
      $(".slide--cnt").removeClass("fadeInUp");
      $(".slick-active .slide--cnt").addClass("fadeInUp");
    }
  );
}

function scrollInsideChefsArray() {
  $(".alphabet a").on("click", function (event) {
    event.preventDefault();

    var targetAnchor = $(this).attr("href");
    if ($(targetAnchor).length) {
      $("html, body").animate(
        { scrollTop: $(targetAnchor).offset().top - 300 },
        600
      );
    }
  });
}

function customMenu() {
  $("#nav-mobile > li.menu-item-has-children").each(function () {
    var parent = $(this);
    var brothers = $(this).siblings(".menu-item-has-children");
    var childs = $(
      "#nav-mobile > li.menu-item-has-children > ul.sub-menu > li.menu-item-has-children"
    );

    $(this)
      .children("a")
      .on("click", function (event) {
        event.preventDefault();
        brothers.removeClass("active").children("ul.sub-menu").slideUp();
        childs.removeClass("active").children("ul.sub-menu").slideUp();

        parent
          .toggleClass("active")
          .children("ul.sub-menu")
          .slideToggle(function () {
            if ($(this).is(":visible")) {
              $(this).css("display", "block");
            }
          });
      });
  });
}

function filterEventsByYear(year) {
  console.log(year);

  $.ajax({
    url: "/wp-admin/admin-ajax.php",
    type: "POST",
    data: {
      action: "filter_events_by_year", // Action WordPress pour gérer la requête AJAX
      year: year, // L'année à filtrer
    },
    success: function (response) {
      // Afficher les événements filtrés dans la grille d'annonces
      $(".grille-annonce--l3").html(response);
    },
    error: function (xhr, status, error) {
      console.error(xhr.responseText);
    },
  });
}
